import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {Lookup} from 'app/common/lookup';
import {FormGroup, Validators, FormControl, FormBuilder} from '@angular/forms';
import {Title} from "@angular/platform-browser";
import {AlertService} from 'app/common/alert.service';

declare var $: any;


@Component({
  selector: 'app-updatecity',
  templateUrl: './updatecity.component.html',
  styleUrls: ['./updatecity.component.css']
})
export class UpdatecityComponent implements OnInit {

    constructor(private http: Http, private router: Router,
                private route: ActivatedRoute, private lookUp: Lookup, private title: Title, private alertService: AlertService) {
    }

    CityForm: FormGroup;
    id: number;
    citydetail: object = {};
    countryTypes = [];
    stateTypes = [];
    country_id: number;


    getLookUp = function(){
        this.lookUp.getLookUp('countries').subscribe((data) => {if (data) {this.countryTypes = data; }});
        this.lookUp.getLookUp('states').subscribe((data) => {if (data) {this.stateTypes = data; }});
    };

    deletecity = function (id) {
        if (confirm('Are you sure you want to delete this record?')) {
            this.city = {
                'id': id,
                'deleted_by': Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + '/deletecity', this.city).subscribe((res: Response) => {
                this.router.navigate(['/cities']);
            });
        }
    };

    updateCity = function (city) {
        if (this.CityForm.valid) {
            city.id = this.id;
            // city.country_id = city.country_id['id'];
            city.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/updatecity', city).subscribe((res: Response) => {
                this.router.navigate(['/cities']);
            });
        } else{
            this.alertService.validationAlert();
        }
    };

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');

        this.CityForm = new FormGroup({
            name: new FormControl('', Validators.compose([Validators.required])),
            state_id: new FormControl('', Validators.required),
            country_id: new FormControl('', Validators.required)
        });

        this.getLookUp();
        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + '/citydetails?id=' + this.id).subscribe(data => {
            this.citydetail = data.json();
        });

        this.title.setTitle('Octanics - City');
    }


}
