import {NgModule, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FooterComponent} from './shared/footer/footer.component';
import {HeaderComponent} from './shared/header/header.component';
import {LayoutComponent} from './shared/layout/layout.component';
import {LoginComponent} from "./login/login.component";
import {ChangepasswordComponent} from './changepassword/changepassword.component';
import {ProfileComponent} from "./myprofile/profile/profile.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {RedirectComponent} from "./redirect/redirect.component";
import {AuthGuard} from "./auth.guard";
import {SettingsComponent} from './settings/settings.component';
import {CountrylistComponent} from './masters/common/country/countrylist/countrylist.component';
import {InsertcountryComponent} from './masters/common/country/insertcountry/insertcountry.component';
import {UpdatecountryComponent} from './masters/common/country/updatecountry/updatecountry.component';
import {StatelistComponent} from './masters/common/state/statelist/statelist.component';
import {InsertstateComponent} from './masters/common/state/insertstate/insertstate.component';
import {UpdatestateComponent} from './masters/common/state/updatestate/updatestate.component';
import {CitylistComponent} from './masters/common/city/citylist/citylist.component';
import {InsertcityComponent} from './masters/common/city/insertcity/insertcity.component';
import {UpdatecityComponent} from './masters/common/city/updatecity/updatecity.component';
import {DocumenttypelistComponent} from './masters/common/documenttype/documenttypelist/documenttypelist.component';
import {InsertdocumenttypeComponent} from './masters/common/documenttype/insertdocumenttype/insertdocumenttype.component';
import {UpdatedocumenttypeComponent} from './masters/common/documenttype/updatedocumenttype/updatedocumenttype.component';
import {EmployerComponent} from './hr/employer/employer.component';
import {ParametersComponent} from "@app/configuration/parameters/parameters.component";
import {SidemenulayoutComponent} from "@app/shared/sidemenulayout/sidemenulayout.component";
import {BotlistComponent} from "@app/bots/botlist/botlist.component";
import {AuditloglistComponent} from "@app/auditlog/auditloglist/auditloglist.component";
import {DocumentlistComponent} from "@app/tools/document/documentlist/documentlist.component";
import {InsertdocumentComponent} from "@app/tools/document/insertdocument/insertdocument.component";
import {UpdatedocumentComponent} from "@app/tools/document/updatedocument/updatedocument.component";
import {DocumentviewComponent} from "@app/tools/document/documentview/documentview.component";

const appRoutes: Routes = [
    {path: '', component: RedirectComponent},
    {path: 'login', component: LoginComponent},
    {
        path: '',
        component: LayoutComponent,
        children: [
            {path: 'documents', component: DocumentlistComponent, canActivate: [AuthGuard]},
            {path: 'insertdocument', component: InsertdocumentComponent, canActivate: [AuthGuard]},
            {path: 'updatedocument/:id', component: UpdatedocumentComponent, canActivate: [AuthGuard]},
            {path: 'documentview/:id', component: DocumentviewComponent, canActivate: [AuthGuard]},
            {path: 'auditlogs', component: AuditloglistComponent, canActivate: [AuthGuard]},
            {path: 'bots', component: BotlistComponent, canActivate: [AuthGuard]},
            {path: 'parameters', component: ParametersComponent, canActivate: [AuthGuard]},
            {path: 'countries', component: CountrylistComponent, canActivate: [AuthGuard]},
            {path: 'insertcountry', component: InsertcountryComponent, canActivate: [AuthGuard]},
            {path: 'updatecountry/:id', component: UpdatecountryComponent, canActivate: [AuthGuard]},
            {path: 'states', component: StatelistComponent, canActivate: [AuthGuard]},
            {path: 'insertstate', component: InsertstateComponent, canActivate: [AuthGuard]},
            {path: 'updatestate/:id', component: UpdatestateComponent, canActivate: [AuthGuard]},
            {path: 'cities', component: CitylistComponent, canActivate: [AuthGuard]},
            {path: 'insertcity', component: InsertcityComponent, canActivate: [AuthGuard]},
            {path: 'updatecity/:id', component: UpdatecityComponent, canActivate: [AuthGuard]},
            {path: 'documenttypes', component: DocumenttypelistComponent, canActivate: [AuthGuard]},
            {path: 'insertdocumenttype', component: InsertdocumenttypeComponent, canActivate: [AuthGuard]},
            {path: 'updatedocumenttype/:id', component: UpdatedocumenttypeComponent, canActivate: [AuthGuard]},
            {path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard]},
            {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
            {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
            {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]}
        ]
        // , canActivate: [AuthGuard]
    },
    {
        path: '',
        component: SidemenulayoutComponent,
        children: [

        ]
    },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);