import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {AlertService} from "@app/common/alert.service";

declare var $: any;


@Component({
  selector: 'app-insertdocumenttype',
  templateUrl: './insertdocumenttype.component.html',
  styleUrls: ['./insertdocumenttype.component.css']
})
export class InsertdocumenttypeComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private httpclient: HttpClient,
                private title: Title, private alertService: AlertService) {
    }

    DocumentTypeForm: FormGroup;
    Utility = Utility;

    insertDocumentType = function(documenttype) {
        if (this.DocumentTypeForm.valid) {
            documenttype.created_by = Utility.getLoggedUserId();
            documenttype.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + "/insertdocumenttype", documenttype).subscribe((res: Response) => {
                this.router.navigate(['/documenttypes']);
            });
        } else{
            this.alertService.validationAlert();
        }
    };


    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');

        this.DocumentTypeForm = new FormGroup({
            name: new FormControl('', Validators.required),
        });
        this.title.setTitle('Octanics - Document Type');
    }


}
