import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';

declare var $: any;



@Component({
  selector: 'app-auditloglist',
  templateUrl: './auditloglist.component.html',
  styleUrls: ['./auditloglist.component.css']
})
export class AuditloglistComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title) {
    }

    ngOnInit() {

        $('.sidenav li.active').removeClass('active');
        $('#menuAuditlog').addClass('active');

        this.title.setTitle('Octanics - Audit Log');
    }

}
