import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Title} from "@angular/platform-browser";
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {AlertService} from "@app/common/alert.service";

declare var $: any;

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }
}

@Component({
  selector: 'app-updatecountry',
  templateUrl: './updatecountry.component.html',
  styleUrls: ['./updatecountry.component.css']
})
export class UpdatecountryComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,
                private title: Title, private httpclient: HttpClient, private alertService: AlertService) {
    }
    id: number;
    countrydetail: object = {};
    CountryForm: FormGroup;
    selectedFilePath: string;
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    uploadingFilePercents = 0;
    uploadingFile = false;
    filetypeValidation = '';
    selectedFile: ImageSnippet;
    companyShortName = Utility.getCompanyShortName();

    deletecountry = function(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.country = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deletecountry", this.country).subscribe((res: Response) => {
                this.router.navigate(['/countries']);
            });
        }
    };

    deleteCountryFlag() {
        if (confirm('Are you Sure, You want to remove Product Image?')) {
            this.selectedFilePath = null;
            this.countrydetail['flag'] = null;
        }
    }

    updateCountry = function(country) {
        if (this.CountryForm.valid && this.filetypeValidation == '') {
            country.id = this.id;
            country.flag = this.selectedFilePath ? this.selectedFilePath : this.countrydetail['flag'];
            country.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + "/updatecountry", country).subscribe((res: Response) => {
                this.router.navigate(['/countries']);
            });
        }  else{
            this.alertService.validationAlert();
        }
    };

    uploadMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.filetypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.filetypeValidation = 'Selected file type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.filetypeValidation = '';
        }
    }

    public uploadDocument(productimage: any) {
        const formData = new FormData();
        formData.append('file', productimage);
        formData.append('filename', productimage.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/countryflagimages');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.CountryForm = new FormGroup({
            name: new FormControl('', Validators.compose([
                Validators.required
            ])),
            countrycode: new FormControl('', Validators.compose([
                Validators.required
            ])),
            flag: new FormControl(null)
        });
        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });
        this.http.get(Utility.serviceCoreURL + "/countrydetails?id=" + this.id).subscribe(data => {
            this.countrydetail = data.json();
             this.selectedFilePath = this.countrydetail['flag'];
        });
        this.title.setTitle('Octanics - Country');
        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
    }

}
