import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';
import {Title} from "@angular/platform-browser";
import {AlertService} from "@app/common/alert.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

    constructor(private http: HttpClient, private router: Router, private title: Title,  private route: ActivatedRoute,
                private alertService: AlertService) {
    }

    generalSettingsForm: FormGroup;
    errorMessage = '';
    email: string;
    count: number = 0;
    settings: object = [];
    id: number;
    length: number;

    saveSettings = function(generalsettings) {

        if (this.generalSettingsForm.valid) {
            if (Utility.isNull(this.settings)) {
                generalsettings.created_by = Utility.getLoggedUserId();
                generalsettings.modified_by = Utility.getLoggedUserId();
                this.http.post(Utility.serviceCoreURL + "/insertsettings", generalsettings).subscribe((res: Response) => {
                    if (res) {
                        this.router.navigate(['/dashboard']);
                    }
                });
            } else {
                generalsettings.id = this.id;
                generalsettings.modified_by = Utility.getLoggedUser;
                this.http.post(Utility.serviceCoreURL + "/updatesettings", generalsettings).subscribe((res: Response) => {
                    if (res) {
                        this.router.navigate(['/dashboard']);
                    }
                });
            }
        } else {
            this.alertService.validationAlert();
        }
    };


    ngOnInit() {

        this.http.get(Utility.serviceCoreURL + '/getsettings').subscribe(data => {
            this.settings = data;
            this.id = this.settings['id'];
        });
        this.title.setTitle('Octanics - Settings');

        this.generalSettingsForm = new FormGroup({
            name: new FormControl('', Validators.required),
            address: new FormControl('', Validators.required),
            contactno: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required),
            terms: new FormControl('', Validators.required),
        });
    }



}
