import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Validate} from 'app/common/validate';
import {DatePipe} from '@angular/common';
import {Document} from "@app/models/document.model";
import {Common} from "@app/common/common";
import {Title} from '@angular/platform-browser';
import {AlertService} from "@app/common/alert.service";

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }
}
declare var $: any;

@Component({
  selector: 'app-updatedocument',
  templateUrl: './updatedocument.component.html',
  styleUrls: ['./updatedocument.component.scss']
})
export class UpdatedocumentComponent implements OnInit {

    constructor(private http: Http, private title: Title, private router: Router,
                private httpclient: HttpClient, private route: ActivatedRoute,
                private datePipe: DatePipe, private alertService: AlertService) {
    }
    documentdetail: object = {};
    Documents: Array<Document> = [];
    DocumentForm: FormGroup;
    successMessage = '';
    selectedFile: ImageSnippet;
    selectedFileOriginalName: string;
    uploadingDocumentPercents = 0;
    selectedDocumentFile: ImageSnippet;
    selectedDocumentFilePath: string = null;
    selectedFilePath: string;
    documentValidation: boolean;
    documentEditIndex: number = -1;
    document_id: number;
    areaId: number;
    routes: string;
    errorMessage = '';
    documentErrorMessage = '';
    documentValidationMessage: string = '';
    documentSizeLimit = localStorage.getItem('docsizelimit');
    documentFileSizeLimit = this.formatBytes(this.documentSizeLimit);
    //documentTitle: string;
    uploadingFile = false;
    Utility = Utility;
    filetypeValidation = '';
    uploadingFilePercents = 0;
    companyShortName = Utility.getCompanyShortName();
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    id: number;

    deleteDocument = function(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.document = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deletedocument", this.document).subscribe((res: Response) => {
                this.router.navigate(['/documents']);
            });
        }
    };

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    uploadMultiple(event) {
        if (event.target.files.length > 0) {
            let re = /(?:\.([^.]+))?$/;
            let x = re.exec(event.target.files[0].name);
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.filetypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.filetypeValidation = 'Selected file type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.filetypeValidation = '';
        }
    }

    public uploadDocument(document: any) {
        const formData = new FormData();
        formData.append('file', document);
        formData.append('filename', document.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/documents');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }

    saveDocument(document) {
        if(this.selectedFilePath == null && (document.document == '' || document.document == undefined)) {
            this.documentValidation = false;
            this.documentValidationMessage = 'Please Select Document';
            this.alertService.validationAlert();
        }else {
            this.documentValidation = true;
        }
        if (this.DocumentForm.valid && this.filetypeValidation == '' && this.documentValidation) {
            document.id = this.id;
            document.document = this.selectedFilePath ? this.selectedFilePath : this.documentdetail['documentpath'];
            document.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/updatedocument', document).subscribe((res: Response) => {
                this.router.navigate(['/documents']);
            });
        } else {
            this.alertService.validationAlert();
        }
    }

    deleteDocumentFlag() {
        if (confirm('Are you Sure, You want to remove Product Image?')) {
            this.selectedFilePath = null;
            this.documentdetail['documentpath'] = null;
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menutools').addClass('active');


        this.title.setTitle('Octanics - Document');

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });
        this.http.get(Utility.serviceCoreURL + "/documentdetails?id=" + this.id).subscribe(data => {
            this.documentdetail = data.json();
            this.selectedFilePath = this.documentdetail['documentpath'];
        });

        this.DocumentForm = new FormGroup({
            title: new FormControl('', Validators.required),
            document: new FormControl('', null)
        });

        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
    }

}
