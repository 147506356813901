import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title) {
    }

  ngOnInit() {

      $('.sidenav li.active').removeClass('active');
      $('#menuDashboard').addClass('active');

      this.title.setTitle('Octanics - Dashboard');

  }

}
