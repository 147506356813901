import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {Sorting} from "@app/common/sorting";
import {City} from 'app/models/city.model';

declare var $: any;
@Component({
  selector: 'app-citylist',
  templateUrl: './citylist.component.html',
  styleUrls: ['./citylist.component.css']
})
export class CitylistComponent implements OnInit {

    constructor(private http: Http, private title: Title) {
    }

    searchText;
    city: City;
    Common = Common;
    cities = [];
    p: Number = 1;
    count: Number = 20;
    Sorting = Sorting;
    order: boolean = true;
    property: string = null;

    sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }

    getcityList = function () {
        this.http.get(Utility.serviceCoreURL + '/cities').subscribe(data => {
            this.cities = data.json();
        });
    };

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.getcityList();
        this.title.setTitle('Octanics - Cities');
    }

}
