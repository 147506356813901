export class Directors {
  id:number;
  director: string;
  employer_id:number;
  firstname:string;
  lastname:string;
  contactno:string;
  active:boolean;
  created_by:number;
  created_at: string;
  modified_by:number;
  modified_at: string;
  deleted_by:number;
  deleted_at: string;
  entitystate: string;
}
