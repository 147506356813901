import {Component, OnInit} from '@angular/core';
import {Utility} from 'app/common/utility';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import {Branchlocation} from "@app/models/branchlocation.model";
import {Directors} from "@app/models/directors.model";
import {Lookup} from 'app/common/lookup';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {AlertService} from "@app/common/alert.service";
import {Businessaddresses} from "@app/models/businessaddresses.model";

declare var $: any;

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }
}


@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['./employer.component.css']
})
export class EmployerComponent implements OnInit {

    uploadingFilePercents = 0;

    EmployerForm: FormGroup;
    EmployerAddressForm: FormGroup;
    successMessage = '';
    errorMessage = '';
    countries = [];
    states = [];
    city = [];
    addresstypes = [];
    employerDetails = [];
    selectedFile: ImageSnippet;
    selectedFilePath: string;
    selectedCompanyStampPath: string;
    selectedDigitalSignaturePath: string;
    companyShortName = Utility.getCompanyShortName();
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    employers: object = [];
    companylogo: string;
    companystamp: string;
    digitalsignature: string;
    employerdetail: object = [];
    branchlocationeditindex: number;
    directoreditindex: number;
    branchLocation: Array<Branchlocation> = [];
    employeraddresses: Array<Businessaddresses> = [];
    employeraddresseditindex: number;
    employerAddressFieldsValidation = '';
    directors: Array<Directors> = [];
    uploadingFile = false;
    uploadingCompanyStampPathPercents = 0;
    uploadingCompanyStampPath = false;
    uploadingDigitalSignaturePathPercents = 0;
    uploadingDigitalSignaturePath = false;
    companylogoFiletypeValidation = '';
    digitalsignatureFiletypeValidation = '';
    companystampFiletypeValidation = '';
    branchlocationFieldsValidation = '';
    directorFieldsValidation = '';
    cityId: number;
    stateId:number;
    address1 : string;

    //name = $('#name').html(editdata.firstname + " " + editdata.lastname);

    constructor(private http: Http, private router: Router, private httpclient: HttpClient,
                private title: Title, private route: ActivatedRoute,
                private lookUp: Lookup, private alertService: AlertService) {
    }

    getLookUp() {
        this.lookUp.getLookUp('countries').subscribe((data) => {
            if (data) {
                this.countries = data;
            }
        });
        this.lookUp.getLookUp('states').subscribe((data) => {
            if (data) {
                this.states = data;
            }
        });
        this.lookUp.getLookUp('cities').subscribe((data) => {
            if (data) {
                this.city = data;
            }
        });
        this.lookUp.getLookUp('addresstypes').subscribe((data) => {
            if (data) {
                this.addresstypes = data;
            }
        });
    }

    uploadMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.companylogoFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.companylogoFiletypeValidation = 'Selected Company logo type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.companylogoFiletypeValidation = '';
        }
    }

    public uploadDocument(companylogo: any) {
        const formData = new FormData();
        formData.append('file', companylogo);
        formData.append('filename', companylogo.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/employerdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });
        this.httpclient.request(req).subscribe(event => {
            this.selectedFilePath = this.companylogo = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }

    deleteCompanyLogo() {
        if (confirm('Are you Sure, You want to remove Company Logo?')) {
            this.companylogo = null;
            $('#companylogo').val('');
        }
    }

    uploadCompanyStampMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.companystampFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadCompanyStampDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.companystampFiletypeValidation = 'Selected Company stamp type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.companystampFiletypeValidation = '';
        }
    }

    public uploadCompanyStampDocument(companystamp: any) {
        const formData = new FormData();
        formData.append('file', companystamp);
        formData.append('filename', companystamp.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/employerdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedCompanyStampPath = this.companystamp = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingCompanyStampPathPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingCompanyStampPath = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingCompanyStampPath = false;
            }
        });
    }

    deleteCompanyStamp() {
        if (confirm('Are you Sure, You want to remove Company Stamp?')) {
            this.companystamp = null;
            $('#companystamp').val('');
        }
    }

    uploadDigitalSignatureMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.digitalsignatureFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDigitalSignatureDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.digitalsignatureFiletypeValidation = 'Selected Digital signature type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.digitalsignatureFiletypeValidation = '';
        }
    }


    public uploadDigitalSignatureDocument(digitalsignature: any) {
        const formData = new FormData();
        formData.append('file', digitalsignature);
        formData.append('filename', digitalsignature.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/employerdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);
        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedDigitalSignaturePath = this.digitalsignature = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingDigitalSignaturePathPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingDigitalSignaturePath = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingDigitalSignaturePath = false;
            }
        });
    }




    deleteDigitalSignature() {
        if (confirm('Are you Sure, You want to remove Digital Signature?')) {
            this.digitalsignature = null;
            $('#digitalsignature').val('');
        }
    }

    getEmployer() {
        this.http.get(Utility.serviceCoreURL + '/getemployers').subscribe(data => {
            data = data.json();
            this.employers = data['employerdetail'];
            this.directors = data['directors'];
            this.employeraddresses = data['employeraddresses'];
            if (!Utility.isNull(this.employers)) {
                // localStorage.setItem('companyshortname', this.employers['companyshortname']);
                this.companylogo = this.employers['image'];
                this.companystamp = this.employers['companystamp'];
                this.digitalsignature = this.employers['digitalsignature'];
                this.EmployerForm.controls['nameoforganization'].setValue(this.employers['nameoforganization']);
                this.EmployerForm.controls['registration'].setValue(this.employers['registration']);
                this.EmployerForm.controls['pan'].setValue(this.employers['pan']);
                this.EmployerForm.controls['tan'].setValue(this.employers['tan']);
                this.EmployerForm.controls['incometaxid'].setValue(this.employers['incometaxid']);
                this.EmployerForm.controls['gstnumber'].setValue(this.employers['gstnumber']);
                this.EmployerForm.controls['saccode'].setValue(this.employers['saccode']);
                this.EmployerForm.controls['employercode'].setValue(this.employers['employercode']);
                this.EmployerForm.controls['bankname'].setValue(this.employers['bankname']);
                this.EmployerForm.controls['bankaccountname'].setValue(this.employers['bankaccountname']);
                this.EmployerForm.controls['bankbranchname'].setValue(this.employers['bankbranchname']);
                this.EmployerForm.controls['ifsc'].setValue(this.employers['ifsc']);
                this.EmployerForm.controls['bankaccountno'].setValue(this.employers['bankaccountno']);
                this.EmployerForm.controls['bankaddress1'].setValue(this.employers['bankaddress1']);
                this.EmployerForm.controls['bankaddress2'].setValue(this.employers['bankaddress2']);
                this.EmployerForm.controls['bankaddress3'].setValue(this.employers['bankaddress3']);
                this.EmployerForm.controls['bankcity_id'].setValue(this.employers['bankcity_id']);
                this.EmployerForm.controls['bankstate_id'].setValue(this.employers['bankstate_id']);
                this.EmployerForm.controls['bankcountry_id'].setValue(this.employers['bankcountry_id']);
                this.EmployerForm.controls['bankpincode'].setValue(this.employers['bankpincode']);
            }

            $("#success-alert").fadeTo(2000, 500).slideUp(500, function () {
                $("#success-alert").slideUp(500);
            });
        });
    }


    insertEmployerAddress(employeraddress) {
        if (this.EmployerAddressForm.valid) {
            if (this.employeraddresseditindex !== undefined && this.employeraddresseditindex >= 0) {
                this.employeraddresses[this.employeraddresseditindex].addresstype_id = $('#addresstype_id option:selected').val() === undefined ? null : $('#addresstype_id option:selected').val();
                this.employeraddresses[this.employeraddresseditindex].addresstype = $('#addresstype_id option:selected').val() === 'null' ? null : $('#addresstype_id option:selected').text();
                this.employeraddresses[this.employeraddresseditindex].email = $('#email').val();
                this.employeraddresses[this.employeraddresseditindex].address1 = $('#address1').val();
                this.employeraddresses[this.employeraddresseditindex].address2 = $('#address2').val();
                // this.employerAddresses[this.employeraddresseditindex].city_id = $('#city_id option:selected').val();
                this.employeraddresses[this.employeraddresseditindex].city_id = $('#city_id option:selected').val() === undefined ? null : $('#city_id option:selected').val();
                this.employeraddresses[this.employeraddresseditindex].city = $('#city_id option:selected').val() === 'null' ? null : $('#city_id option:selected').text();
                this.employeraddresses[this.employeraddresseditindex].state_id = $('#state_id option:selected').val() === undefined ? null : $('#state_id option:selected').val();
                this.employeraddresses[this.employeraddresseditindex].state = $('#state_id option:selected').val() === 'null' ? null : $('#state_id option:selected').text();
                this.employeraddresses[this.employeraddresseditindex].country_id = $('#country_id option:selected').val() === undefined ? null : $('#country_id option:selected').val();
                this.employeraddresses[this.employeraddresseditindex].country = $('#country_id option:selected').val() === 'null' ? null : $('#country_id option:selected').text();
                this.employeraddresses[this.employeraddresseditindex].pincode = $('#pincode').val();
                this.employeraddresses[this.employeraddresseditindex].telephone = $('#telephone').val();
                this.employeraddresses[this.employeraddresseditindex].mobilenoofowner = $('#mobilenoofowner').val();
                if ($('#employeraddress_id').val() > 0) {
                    this.employeraddresses[this.employeraddresseditindex].entitystate = 'Modified';
                } else {
                    this.employeraddresses[this.employeraddresseditindex].entitystate = 'Added';
                }
            } else {
                const customObj = new Businessaddresses();
                // customObj.addresstype_id = $('#addresstype_id').val();
                customObj.addresstype_id = $('#addresstype_id option:selected').val() === undefined ? null : $('#addresstype_id option:selected').val();
                customObj.addresstype = $('#addresstype_id option:selected').val() === 'null' ? null : $('#addresstype_id option:selected').text();
                customObj.email = $('#email').val();
                customObj.address1 = $('#address1').val();
                customObj.address2 = $('#address2').val();
                customObj.city_id = $('#city_id option:selected').val() === undefined ? null : $('#city_id option:selected').val();
                customObj.city = $('#city_id option:selected').val() === 'null' ? null : $('#city_id option:selected').text();
                customObj.state_id = $('#state_id option:selected').val() === undefined ? null : $('#state_id option:selected').val();
                customObj.state = $('#state_id option:selected').val() === 'null' ? null : $('#state_id option:selected').text();
                customObj.country_id = $('#country_id option:selected').val() === undefined ? null : $('#country_id option:selected').val();
                customObj.country = $('#country_id option:selected').val() === 'null' ? null : $('#country_id option:selected').text();
                customObj.pincode = $('#pincode').val();
                customObj.telephone = $('#telephone').val();
                customObj.mobilenoofowner = $('#mobilenoofowner').val();
                customObj.entitystate = 'Added';
                this.employeraddresses.push(customObj);
            }
            this.clearEmployerAddressControls();
        } else {
            this.alertService.validationAlert();
        }
    }

    editEmployerAddress(i, editdata) {
        this.cityId = editdata.city_id;
        this.stateId = editdata.state_id;
        this.address1 = editdata.address1;
        $('#employeraddress_id').val(editdata.id);
        $('#addresstype_id').val(editdata.addresstype_id);
        $('#email').val(editdata.email);
        // $('#address1').val(editdata.address1);
        $('#address2').val(editdata.address2);
        //  $('#city_id').val(editdata.city_id);
        //  $('#state_id').val(editdata.state_id);
        $('#country_id').val(editdata.country_id);
        $('#pincode').val(editdata.pincode);
        $('#telephone').val(editdata.telephone);
        $('#mobilenoofowner').val(editdata.mobilenoofowner);
        this.employeraddresseditindex = i;
    }

    deleteEmployerAddress(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            if (this.employeraddresses[id].id !== undefined) {
                this.employeraddresses[id].entitystate = "Deleted";
            } else {
                this.employeraddresses.splice(id, 1);
            }
        }
    }
    clearEmployerAddressControls() {
        $('#addresstype_id').val('');
        $('#email').val('');
        $('#address1').val('');
        $('#address2').val('');
        $('#city_id').val('');
        $('#state_id').val('');
        $('#country_id').val('');
        $('#pincode').val('');
        $('#telephone').val('');
        $('#mobilenoofowner').val('');
        $('#frmemployeraddress').get(0).reset();
        this.employeraddresseditindex = -1;
    }

    insertdirectors(directors) {
        if ($('#firstname').val() != undefined && $('#firstname').val() != '') {
            this.directorFieldsValidation = '';
            if (this.directoreditindex !== undefined && this.directoreditindex >= 0) {
                this.directors[this.directoreditindex].firstname = $('#firstname').val();
                this.directors[this.directoreditindex].lastname = $('#lastname').val();
                this.directors[this.directoreditindex].contactno = $('#contactno').val();
                this.directors[this.directoreditindex].director = $('#firstname').val() + " " + $('#lastname').val();
                if ($('#director_id').val() > 0) {
                    this.directors[this.directoreditindex].entitystate = 'Modified';
                } else {
                    this.directors[this.directoreditindex].entitystate = 'Added';
                }
            } else {
                const customObj = new Directors();
                customObj.firstname = $('#firstname').val();
                customObj.lastname = $('#lastname').val();
                customObj.contactno = $('#contactno').val();
                customObj.director = $('#firstname').val() + " " + $('#lastname').val();
                customObj.entitystate = 'Added';
                this.directors.push(customObj);
            }
            this.clearDirectorsControls();
            $('.modal').modal('hide');
        } else {
            this.directorFieldsValidation = 'First Name is required';
        }
    }

    editdirector(i, editdata) {
        this.clearDirectorsControls();
        $('#director_id').val(editdata.id);
        $('#firstname').val(editdata.firstname);
        $('#lastname').val(editdata.lastname);
        $('#contactno').val(editdata.contactno);
        this.directoreditindex = i;
    }

    deletedirector(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            if (this.directors[id].id !== undefined) {
                this.directors[id].entitystate = "Deleted";
            } else {
                this.directors.splice(id, 1);
            }
        }
    }

    clearDirectorsControls() {
        $('#firstname').val('');
        $('#lastname').val('');
        $('#contactno').val('');
        this.directorFieldsValidation = '';
        this.directoreditindex = -1;
    }


    saveEmployerSettings(employer) {
        if (this.EmployerForm.valid && this.companylogoFiletypeValidation == '' && this.companystampFiletypeValidation == '' && this.digitalsignatureFiletypeValidation == '') {
            if (Utility.isNull(this.employers)) {
                employer.created_by = Utility.getLoggedUserId();
                employer.modified_by = Utility.getLoggedUserId();
                employer.director = this.directors;
                employer.employeraddresses = this.employeraddresses;
                employer.companylogo = this.selectedFilePath ? this.selectedFilePath : null;
                employer.companystamp = this.selectedCompanyStampPath ? this.selectedCompanyStampPath : null;
                employer.digitalsignature = this.selectedDigitalSignaturePath ? this.selectedDigitalSignaturePath : null;
                this.http.post(Utility.serviceCoreURL + '/insertemployer', employer).subscribe((res: Response) => {
                    if (res) {
                        this.getEmployer();
                        this.successMessage = 'Employer Inserted Successfully';
                        this.alertService.validationAlert();
                    }
                });
            } else {
                employer.id = this.employers['id'];
                employer.modified_by = Utility.getLoggedUserId();
                employer.director = this.directors;
                employer.employeraddresses = this.employeraddresses;
                employer.companylogo = this.selectedFilePath ? this.selectedFilePath : this.companylogo;
                employer.companystamp = this.selectedCompanyStampPath ? this.selectedCompanyStampPath : this.companystamp;
                employer.digitalsignature = this.selectedDigitalSignaturePath ? this.selectedDigitalSignaturePath : this.digitalsignature;
                this.http.post(Utility.serviceCoreURL + '/updateemployer', employer).subscribe((res: Response) => {
                    if (res) {
                        this.getEmployer();
                        this.successMessage = 'Employer Updated Successfully';
                        this.alertService.validationAlert();
                    }
                });
            }
        } else {
            this.alertService.validationAlert();
        }
    }




    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menuHR').addClass('active');

        this.getLookUp();
        this.getEmployer();
        this.title.setTitle('Octanics - Employer');
        this.EmployerForm = new FormGroup({
            nameoforganization: new FormControl('', Validators.required),
            companylogo: new FormControl(null),
            digitalsignature: new FormControl(null),
            companystamp: new FormControl(null),
            gstnumber: new FormControl(null),
            saccode: new FormControl(null),
            employercode: new FormControl(null),
            mobile2: new FormControl(null),
            registration: new FormControl(null),
            pan: new FormControl(null),
            tan: new FormControl(null),
            bankname: new FormControl(null),
            bankbranchname: new FormControl(null),
            ifsc: new FormControl(null),
            bankaccountno: new FormControl(null),
            bankaccountname: new FormControl(null),
            bankaddress1: new FormControl(null),
            bankaddress2: new FormControl(null),
            bankaddress3: new FormControl(null),
            bankcity_id: new FormControl(null),
            bankcountry_id: new FormControl(null),
            bankstate_id: new FormControl(null),
            bankpincode: new FormControl(null),
            incometaxid: new FormControl(null),
        });

        this.EmployerAddressForm = new FormGroup({
            addresstype_id: new FormControl(null),
            email: new FormControl(null),
            address1: new FormControl('', Validators.required),
            address2: new FormControl(null),
            city_id: new FormControl('', Validators.required),
            state_id: new FormControl('', Validators.required),
            country_id: new FormControl(null),
            pincode: new FormControl(null),
            telephone: new FormControl(null),
            mobilenoofowner: new FormControl(null),
        });

        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
    }

}
