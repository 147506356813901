export class Businessaddresses {
    id:number;
    employee_id:number;
    addresstype_id:number;
    addresstype: string;
    email:string;
    address1:string;
    address2:string;
    city_id:number;
    city: string;
    state_id:number;
    state: string;
    country_id:number;
    country: string;
    pincode:number;
    telephone:number;
    mobilenoofowner:number;
    created_by:number;
    created_at: string;
    modified_by:number;
    modified_at: string;
    deleted_by:number;
    deleted_at: string;
    entitystate: string;
}
