import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Alert, AlertType} from '../models/alert.model';
import {Subscription} from 'rxjs';
import {AlertService} from '../common/alert.service';
import {NavigationStart, Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-validationerror',
  templateUrl: './validationerror.component.html',
  styleUrls: ['./validationerror.component.css']
})
export class ValidationerrorComponent implements OnInit , OnDestroy {
  @Input() form; @Input() errormessage;
  @Input() controlName;
  @Input() submitted;
  @Input() classname;
  @Input() class;
  @Input() errorMsg: string;
  @Input() displayError: string;
  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;
  options;
  constructor(private router: Router, private alertService: AlertService) {}


//   removeAlert(alert) {
//     console.log( $('#le-alert').removeClass(this.class));
//     $('#le-alert').removeClass(this.class);
//   }
//
//   cssClass(alert) {
//     console.log(alert);
//
//     // if (!alert) return;
//     //
//      const classes = ['alert', 'alert-dismissable'];
//     //
//     // // const alertTypeClass = {
//     // //   [AlertType.Success]: 'alert alert-success',
//     // //   [AlertType.Error]: 'alert alert-danger',
//     // //   [AlertType.Info]: 'alert alert-info',
//     // //   [AlertType.Warning]: 'alert alert-warning',
//     // // };
//     //
//      classes.push('alert alert-danger ' + 'fade-' + this.class);
//     //
//     // if (alert.fade) {
//     //   classes.push('fade');
//     // }
//     return classes.join(' ');
//
//   }
//
// css(){
//   $(this).parent().removeClass('in');
//   //return show;
//  //  console.log(show);
// }
  ngOnInit() {
    // $('.alert-close').click(function() {
    //   $('.alert').hide();
    // });



    // $(function() {
    //   $(document).on('click', '.alert-close', function() {
    //     $(this).parent().hide();
    //   });
    // });



    // var hideError = function () {
    //   $(".alert").hide();
    // };
    // $("form").submit(function () {
    //   setTimeout(hideError, 5000);
    // });


    this.alertSubscription = this.alertService
      .onAlert(this.id)
      .subscribe((alert) => {
        this.options = {
          autoClose: true,
          keepAfterRouteChange: false
        };

//         // clear alerts when an empty alert is received
//         if (!alert.message) {
//           // filter out alerts without 'keepAfterRouteChange' flag
           this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange);
// console.log(this.alerts);
//           // remove 'keepAfterRouteChange' flag on the rest
           this.alerts.forEach((x) => delete x.keepAfterRouteChange);
//           return;
//         }
//
//         // add alert to array
         this.alerts.push(alert);

        // auto close alert if required
        if (this.options['autoClose']) {
          setTimeout(() => this.removeAlert(alert), 5000);
        }
      });
        this.alertService.clear(this.id);
    // clear alerts on location change
    // this.routeSubscription = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.alertService.clear(this.id);
    //   }
    // });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
  //  this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter((x) => x !== alert);
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      // fade out alert
      this.alerts.find((x) => x === alert).fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter((x) => x !== alert);
      }, 250);
    } else {
      // remove alert
      this.alerts = this.alerts.filter((x) => x !== alert);
    }
  }


  cssClass(alert) {
      if (!alert) return;
      const classes = ['alert', 'alert-dismissable'];

    // const alertTypeClass = {
    //   [AlertType.Success]: 'alert alert-success',
    //   [AlertType.Error]: 'alert alert-danger',
    //   [AlertType.Info]: 'alert alert-info',
    //   [AlertType.Warning]: 'alert alert-warning',
    // };
    switch (alert.type) {
      case AlertType.Success:
        return classes.join(' ') + 'alert alert-success';
      case AlertType.Error:
        return classes.join(' ') + 'alert alert-danger';
      case AlertType.Info:
        return classes.join(' ') + 'alert alert-info';
      case AlertType.Warning:
        return classes.join(' ') + 'alert alert-warning';
    }
   // classes.push('alert alert-danger');

    // if (alert.fade) {
    //   classes.push('fade');
    // }

    //return classes.join(' ');
  }
}


