import { Component, OnInit } from '@angular/core';
import {Utility} from 'app/common/utility';
import {Http} from '@angular/http';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,private location: Location) { }
    reports = [];
    routes: string;
    roleId: string;


    getReportMenu(event) {
        this.http.get(Utility.serviceCoreURL + '/getmoduleareareport?route=' + event + '&role_id=' + this.roleId).subscribe((data) => {
            if (data['_body'] !== '') {
                this.reports = data.json();
            }
        });

    }
    menucloseclick()
    {
        $('.menuclose a').on('click',function(){
            //alert('hii');
            $('#reportsPlageLeftSidebarMobile').removeClass('show');
        });
    }
    ngOnInit() {
        $(document).ready(function () {
            $('#reportsLeftBtnToggle').on('click',function(){
                $('#reportsPlageLeftSidebarMobile').toggleClass('show');
            });

            $('#reportLinkCloseToggle').on('click',function(){
                $('#reportsPlageLeftSidebarMobile').removeClass('show');
            });
        });
        //this.url=event.substr(0, this.router.url.lastIndexOf("/"));
        this.roleId = localStorage.getItem('roleid');
        this.route.params.subscribe((res) => {
            this.routes= this.router.url;
        })
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.routes= val.url;
                this.getReportMenu( this.routes);
            }
        });
        this.getReportMenu(this.routes);

    }


}
