import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {Title} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-documentview',
  templateUrl: './documentview.component.html',
  styleUrls: ['./documentview.component.css']
})
export class DocumentviewComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title) {
    }
    Common = Common;
    id: number;
    documentdetail: object = {};
    junctionbox = Utility.junctionboxPath();

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menutools').addClass('active');

        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });
        this.http.get(Utility.serviceCoreURL + "/documentdetails?id=" + this.id).subscribe(data => {
            this.documentdetail = data.json();
        });
        this.title.setTitle('Octanics - Document');

    }


}
