import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Lookup} from 'app/common/lookup';
import {City} from "app/models/city.model";
import {Title} from '@angular/platform-browser';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {AlertService} from 'app/common/alert.service';

declare var $: any;


@Component({
  selector: 'app-insertcity',
  templateUrl: './insertcity.component.html',
  styleUrls: ['./insertcity.component.css']
})
export class InsertcityComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,
                private title: Title, private lookUp: Lookup, private alertService: AlertService) {
    }

    public selectControl = new FormControl();
    country_id: number;
    city: City;
    countryTypes = [];
    CityForm: FormGroup;
    stateTypes: Array<any>;


    getLookUp = function(){
        this.lookUp.getLookUp('countries').subscribe((data) => {if (data) {this.countryTypes = data; }});
        this.lookUp.getLookUp('states').subscribe((data) => {if (data) {this.stateTypes = data; }});
    };

    insertCity = function (city) {
        if (this.CityForm.valid) {
            // city.country_id = city.country_id['id'];
            city.created_by = Utility.getLoggedUserId();
            city.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + "/insertcity", city).subscribe((res: Response) => {
                this.router.navigate(['cities']);
            });
        }
        else{
            this.alertService.validationAlert();
        }
    };


    ngOnInit(): void {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.CityForm = new FormGroup({
            country_id: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            state_id: new FormControl('', Validators.required)
        });

        this.getLookUp();
        this.title.setTitle('Octanics - City');

    }

}
