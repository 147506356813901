import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl} from '@angular/forms';
import * as bcrypt from 'bcryptjs';
import {Title} from '@angular/platform-browser';
import {Utility} from '@app/common/utility';
import {HttpClient} from '@angular/common/http';
import {Localstorage} from '@app/common/localstorage';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title,private Localsecurestorage: Localstorage) {
  }
  changePasswordForm: FormGroup;
  errorMessage = '';
  password: object = [];
  id: number;
  UserId: string;
  length: number;

  updatepassword = function (changepassword) {
    if (this.changePasswordForm.valid) {
      this.http.get(Utility.serviceCoreURL + '/getuserbyid?id=' +  this.UserId).subscribe(data => {
        const auth = data;
        this.status = bcrypt.compareSync(changepassword.password, auth['password']);
        if (this.status && (changepassword.newpassword === changepassword.confirmpassword) && (changepassword.newpassword !== '') && (changepassword.confirmpassword !== '')) {
          const salt = bcrypt.genSaltSync(10);
          changepassword.newpassword = bcrypt.hashSync(changepassword.newpassword, salt);
          this.newpassword = {
            'id': this.UserId,
            'newpassword': changepassword.newpassword
          };
          this.http.post(Utility.serviceCoreURL + '/updatepassword', this.newpassword).subscribe(data2 => {
            if (data2['_body'] === 'Some error occurred') {
              this.errorMessage = 'Error!';
            } else {
              localStorage.removeItem('userid');
              localStorage.removeItem('email');
              // localStorage.removeItem('username');
              localStorage.removeItem('firstname');
              localStorage.removeItem('lastname');
              //localStorage.removeItem('rolename');
              //localStorage.removeItem('employeeid');
              localStorage.removeItem('profilephoto');
              //localStorage.removeItem('aliasrolename');
              sessionStorage.removeItem('eid');
              this.router.navigate(['/']);
            }
          });
        } else {
          if (changepassword.password.length) {
            this.errorMessage = "Old password doesn't match";
          }
        }
      });
    }
  };


  /* updatepassword = function (changepassword) {
       changepassword.id = this.UserId;
       changepassword.password = changepassword.newpassword;
       this.http.get(Utility.serviceCoreURL + "/getpassword?id=" + this.UserId + "&password=" + this.password).subscribe(data => {
           if (data['_body'] === '') {
               this.errorMessage = 'Old Password Not Match';
           } else {
               this.http.post(Utility.serviceCoreURL + "/updatepassword", changepassword).subscribe((res: Response) => {
                   this.router.navigate(['/changepassword']);
               });
           }
       });
   };*/



  inputChanged(event: any) {
    this.password = event.target.value;
  }

  ngOnInit() {

    this.title.setTitle('Octanics - Change Password');
    this.UserId = this.Localsecurestorage.getStorage('userid');
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      newpassword: new FormControl('', Validators.required),
      confirmpassword: new FormControl('', [Validators.required, passwordMatchValidator('password')])
    });

    function passwordMatchValidator(password: string): ValidatorFn {
      return (control: FormControl) => {
        if (!control || !control.parent) {
          return null;
        }
        return control.parent.get('newpassword').value === control.value ? null : {mismatch: true};
      };
    }
  }


}
