import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Lookup} from 'app/common/lookup';
import {Common} from 'app/common/common';
import {FormGroup, Validators, FormControl, FormBuilder} from '@angular/forms';
import {Title} from "@angular/platform-browser";
import {AlertService} from "@app/common/alert.service";

declare var $: any;

@Component({
  selector: 'app-updatestate',
  templateUrl: './updatestate.component.html',
  styleUrls: ['./updatestate.component.css']
})
export class UpdatestateComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,
                private formBuilder: FormBuilder, private lookUp: Lookup, private title: Title , private alertService: AlertService) {
    }

    public selectControl = new FormControl();
    statedetail: object = {};
    id: number;
    countryTypes = [];
    StateForm: FormGroup;

    getLookUp = function(){
        this.lookUp.getLookUp('countries').subscribe((data) => {if (data) {this.countryTypes = data; }});
    };

    updateState = function (state) {
        if (this.StateForm.valid) {
            state.id = this.id;
            state.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + "/updatestate", state).subscribe((res: Response) => {
                this.router.navigate(['/states']);
            });
        }
        else{
            this.alertService.validationAlert();
        }
    };

    deletestate = function (id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.state = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };

            this.http.post(Utility.serviceCoreURL + "/deletestate", this.state).subscribe((res: Response) => {
                this.router.navigate(['/states']);
            });
        }
    };

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');

        this.StateForm = new FormGroup({
            name: new FormControl('', Validators.required),
            country_id: new FormControl('', Validators.required),
            statecode: new FormControl(null),
            abbreviation : new FormControl(null)
        });
        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + "/statedetails?id=" + this.id).subscribe(data => {
            this.statedetail = data.json();
        });
        this.getLookUp();
        this.title.setTitle('Octanics - State');
    }


}
