import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {State} from 'app/models/state.model';
import {Lookup} from 'app/common/lookup';
import {Common} from 'app/common/common';
import {Title} from '@angular/platform-browser';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {AlertService} from 'app/common/alert.service';

declare var $: any;

@Component({
  selector: 'app-insertstate',
  templateUrl: './insertstate.component.html',
  styleUrls: ['./insertstate.component.css']
})
export class InsertstateComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title, private lookUp: Lookup, private alertService: AlertService) {
    }

    state: State;
    countryTypes = [];
    StateForm: FormGroup;


    getLookUp = function(){
        this.lookUp.getLookUp('countries').subscribe((data) => {if (data) {this.countryTypes = data; }});
    };
    insertState = function (state) {
        if (this.StateForm.valid) {
            state.created_by = Utility.getLoggedUserId();
            state.modified_by = Utility.getLoggedUserId();
             console.log(state);
            this.http.post(Utility.serviceCoreURL + "/insertstate", state).subscribe((res: Response) => {
                this.router.navigate(['states']);
            });
        }
        else{
            this.alertService.validationAlert();
        }
    };

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');

        this.StateForm = new FormGroup({
            name: new FormControl('', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z\s]+$")])),
            country_id: new FormControl('', Validators.required),
            statecode: new FormControl(null),
            abbreviation : new FormControl(null)
        });

        this.getLookUp();
        this.title.setTitle('Octanics - State');
    }


}
