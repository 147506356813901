import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Localstorage} from '@app/common/localstorage';
import {Utility} from '@app/common/utility';
import {AlertService} from "@app/common/alert.service";

declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private http: HttpClient, private httpclient: HttpClient, private alertService: AlertService,
              private router: Router, private title: Title, private Localsecurestorage: Localstorage) {
  }
  profilephoto: string;
  profileform: FormGroup;
  profiledetail: object = {};
  junctionbox = Utility.junctionboxURL();
  id: string;
  successMessage = '';
  uploadingFilePercents = 0;
  selectedFile: ImageSnippet;
  selectedFilePath = '';
  uploadingFile = false;
  junctionBoxSubDomain = Utility.junctionBoxSubDomain();
  companyShortName = Utility.getCompanyShortName();
  landingURLRoute: string;
  filetypeValidation = '';

  updateProfile(profile) {
    if (this.profileform.valid && this.filetypeValidation == '') {
      profile.id = this.id;
      profile.profilephoto = this.selectedFilePath ? this.selectedFilePath : this.profiledetail['profilephoto'];
      profile.modified_by = Utility.getLoggedUser;
      this.http.post(Utility.serviceCoreURL + '/updateprofiledetail', profile).subscribe((res: Response) => {
        if (res) {
          this.successMessage = 'Profile Updated Successfully';
          this.alertService.validationAlert();
        }
      });
    }  else {
        this.alertService.validationAlert();
    }
  }

  getMyProfile() {
    this.http.get(Utility.serviceCoreURL + '/getmyprofile?id=' + this.id).subscribe(data => {
      this.profiledetail = data;
      this.selectedFilePath = this.profiledetail['profilephoto'];
    });
  }

    uploadMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.filetypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.filetypeValidation = 'Selected file type must be .png, .jpeg, .jpg';
            }
        } else {
            this.filetypeValidation = '';
        }
    }


    public uploadDocument(profilephoto: any) {
        const formData = new FormData();
        formData.append('file', profilephoto);
        formData.append('filename', profilephoto.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/profileimages');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }


  ngOnInit() {
    $('.navbar-collapse li.active').removeClass('active');
    this.id = this.Localsecurestorage.getStorage('userid');

      if (this.junctionBoxSubDomain == 'null') {
          this.junctionBoxSubDomain = null;
      }

    this.profileform = new FormGroup({
      username: new FormControl('', Validators.required),
      productimage: new FormControl(null)
    });

    this.title.setTitle('Octanics - My Profile');
    this.getMyProfile();

    //this.landingURLRoute = localStorage.getItem('landingroute');
  }


}
