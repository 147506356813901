import {Component, EventEmitter, OnInit} from '@angular/core';
import {Common} from '../common/common';
import {Validate} from '../common/validate';
import {Utility} from '../common/utility';
declare var $: any;
@Component({
    selector: 'app-pagination',
    inputs: [ 'itemCount', 'pageSize', 'pageIndex' ],
    outputs: [ 'pageIndexChange' ],
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
    pageIndexChange = new EventEmitter();
    constructor() {
        this.pageSize = 1;
    }
    Utility= Utility;
    _pageIndex: number;
    page: number;
    _itemCount: number;
    _pageSize: number;
    _pageCount: number;
    get itemCount() {
        return this._itemCount;
    }
    set itemCount(value) {
        this._itemCount = value;
        this.updatePageCount();
    }
    get pageSize() {
        return this._pageSize;
    }
    set pageSize(value) {
        this._pageSize = value;
        this.updatePageCount();
    }

    updatePageCount() {
        this._pageCount = Math.ceil(this.itemCount / this.pageSize);
    }

    get pageIndex() {
        return this._pageIndex;
    }
    set pageIndex(value) {
        this._pageIndex = value;
        this.pageIndexChange.emit(this.pageIndex);
    }

    get canMoveToNextPage() : boolean {
        return this.pageIndex < this._pageCount - 1 ? true : false;
    }
    get canMoveToPreviousPage() : boolean {
        return this.pageIndex >= 1 ? true : false;
    }
    goToPage(page) {
        if(page !== '' && !isNaN(this.pageIndex) && page !=='0'){
            this.pageIndex = page-1;
        }
        else{
            this.pageIndex = 0;
        }
    }

    moveToNextPage() {
        if (this.canMoveToNextPage) {
            this.pageIndex++;
        }
    }

    moveToPreviousPage() {
        if (this.canMoveToPreviousPage) {
            this.pageIndex--;
        }
    }
}
