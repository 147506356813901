import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {AlertService} from "@app/common/alert.service";
import {Common} from "@app/common/common";

declare var $: any;

@Component({
  selector: 'app-updatedocumenttype',
  templateUrl: './updatedocumenttype.component.html',
  styleUrls: ['./updatedocumenttype.component.css']
})
export class UpdatedocumenttypeComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private httpclient: HttpClient,
                private title: Title, private alertService: AlertService) {
    }
    id: number;
    documenttypedetail: object = {};
    DocumentTypeForm: FormGroup;
    Utility = Utility;

    updateDocumentType = function(documenttype) {
        if (this.DocumentTypeForm.valid) {
            documenttype.id = this.id;
            documenttype.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + "/updatedocumenttype", documenttype).subscribe((res: Response) => {
                this.router.navigate(['/documenttypes']);
            });
        } else{
            this.alertService.validationAlert();
        }
    };

    deletedocumenttype = function(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.documenttype = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deletedocumenttype", this.documenttype).subscribe((res: Response) => {
                this.router.navigate(['/documenttypes']);
            });
        }
    };

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');

        this.DocumentTypeForm = new FormGroup({
            name: new FormControl('', Validators.required),
        });
        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });
        this.http.get(Utility.serviceCoreURL + "/documenttypedetails?id=" + this.id).subscribe(data => {
            this.documenttypedetail = data.json();
        });
        this.title.setTitle('Octanics - Document Type');
    }



}
