import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';

declare var $: any;


@Component({
  selector: 'app-botlist',
  templateUrl: './botlist.component.html',
  styleUrls: ['./botlist.component.scss']
})
export class BotlistComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title) {
    }

  ngOnInit() {

      $('.sidenav li.active').removeClass('active');
      $('#menuBots').addClass('active');

      this.title.setTitle('Octanics - Bots');
  }

}
