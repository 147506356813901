import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {routing} from './app.routes';
import {AppComponent} from './app.component';
import {LayoutComponent} from './shared/layout/layout.component';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {LoginComponent} from './login/login.component';
import {PaginationComponent} from './pagination/pagination.component';
import {ChangepasswordComponent} from './changepassword/changepassword.component';
import {ProfileComponent} from './myprofile/profile/profile.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RedirectComponent} from './redirect/redirect.component';
import {Localstorage} from './common/localstorage';
import {Securestorage} from './common/securestorage';
import {TokenService} from './common/token.service';
import {AuthGuard} from './auth.guard';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SettingsComponent} from './settings/settings.component';
import {HttpModule} from '@angular/http';
import {Lookup} from "@app/common/lookup";
import {CountrylistComponent} from './masters/common/country/countrylist/countrylist.component';
import {InsertcountryComponent} from './masters/common/country/insertcountry/insertcountry.component';
import {UpdatecountryComponent} from './masters/common/country/updatecountry/updatecountry.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {PaginationModule} from './pagination/pagination.module';
import {ValidationerrorModule} from "@app/validationerror/validationerror.module";
import {AlertService} from "@app/common/alert.service";
import {StatelistComponent} from './masters/common/state/statelist/statelist.component';
import {InsertstateComponent} from './masters/common/state/insertstate/insertstate.component';
import {UpdatestateComponent} from './masters/common/state/updatestate/updatestate.component';
import {CitylistComponent} from './masters/common/city/citylist/citylist.component';
import {InsertcityComponent} from './masters/common/city/insertcity/insertcity.component';
import {UpdatecityComponent} from './masters/common/city/updatecity/updatecity.component';
import {DocumenttypelistComponent} from './masters/common/documenttype/documenttypelist/documenttypelist.component';
import {InsertdocumenttypeComponent} from './masters/common/documenttype/insertdocumenttype/insertdocumenttype.component';
import {UpdatedocumenttypeComponent} from './masters/common/documenttype/updatedocumenttype/updatedocumenttype.component';
import {PopupService} from "./common/popup.service";
import {EmployerComponent} from './hr/employer/employer.component';
import {ParametersComponent} from './configuration/parameters/parameters.component';
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {SidemenuComponent} from './shared/sidemenu/sidemenu.component';
import {SidemenulayoutComponent} from './shared/sidemenulayout/sidemenulayout.component';
import {BotlistComponent} from "@app/bots/botlist/botlist.component";
import {AuditloglistComponent} from "@app/auditlog/auditloglist/auditloglist.component";
import {DocumentlistComponent} from "@app/tools/document/documentlist/documentlist.component";
import {InsertdocumentComponent} from "@app/tools/document/insertdocument/insertdocument.component";
import {UpdatedocumentComponent} from "@app/tools/document/updatedocument/updatedocument.component";
import {DocumentviewComponent} from "@app/tools/document/documentview/documentview.component";

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        PaginationComponent,
        ChangepasswordComponent,
        ProfileComponent,
        DashboardComponent,
        BotlistComponent,
        AuditloglistComponent,
        DocumentlistComponent,
        InsertdocumentComponent,
        UpdatedocumentComponent,
        DocumentviewComponent,
        RedirectComponent,
        SettingsComponent,
        CountrylistComponent,
        InsertcountryComponent,
        UpdatecountryComponent,
        StatelistComponent,
        InsertstateComponent,
        UpdatestateComponent,
        CitylistComponent,
        InsertcityComponent,
        UpdatecityComponent,
        DocumenttypelistComponent,
        InsertdocumenttypeComponent,
        UpdatedocumenttypeComponent,
        EmployerComponent,
        ParametersComponent,
        SidemenuComponent,
        SidemenulayoutComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        routing,
        Ng2SearchPipeModule,
        // PaginationModule
        ValidationerrorModule,
        FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),

    ],
    providers: [
        TokenService,
        AuthGuard,
        DatePipe,
        Lookup,
        Localstorage,
        Securestorage,
        AlertService,
        PopupService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
