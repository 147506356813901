import * as CryptoJS from 'crypto-js';
import {FormGroup} from "@angular/forms";

export class Common {

    public static PageIndex = 0;

    public static PageSize(){
        return localStorage.getItem('pageSize');
    }

    public static  isNumberNanOrInfinity(value) {
    if (Number.isNaN(value) || value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY) {
      return 0;
    }else {
      return value;
    }
  }
  public static encryptId(id) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(id), 'masterKey').toString();
    } catch (e) {
    }
  }

  public static decryptId(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, 'masterKey');
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    } catch (e) {
    }
  }

  public static noOfMonths(timeperiod) {
    if (timeperiod == 'MONTHLY') {
      return 1;
    } else if (timeperiod == 'QUARTERLY') {
      return 3;
    } else if (timeperiod == 'HALF YEARLY') {
      return 6;
    } else if (timeperiod == 'YEARLY') {
      return 12;
    }
  }

    public static getRoute(route){
        if(route.lastIndexOf('/')==0){
            return route;
        }else {
            return route.substr(0, route.lastIndexOf('/'));
        }
    }

    public static addValidators(form: FormGroup, validation) {
        for (const key in form.controls) {
            form.get(key).setValidators(validation[key]);
            form.get(key).updateValueAndValidity();
        }
    }

    public static removeValidators(form: FormGroup) {
        for (const key in form.controls) {
            form.get(key).clearValidators();
            form.get(key).updateValueAndValidity();
        }
    }
}
