import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Validate} from 'app/common/validate';
import {DatePipe} from '@angular/common';
import {Document} from "@app/models/document.model";
import {Common} from "@app/common/common";
import {Title} from '@angular/platform-browser';
import {AlertService} from "@app/common/alert.service";

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }
}
declare var $: any;

@Component({
  selector: 'app-insertdocument',
  templateUrl: './insertdocument.component.html',
  styleUrls: ['./insertdocument.component.scss']
})
export class InsertdocumentComponent implements OnInit {

    constructor(private http: Http, private title: Title, private router: Router,
                private httpclient: HttpClient, private route: ActivatedRoute,
                private datePipe: DatePipe, private alertService: AlertService) {
    }

    Documents: Array<Document> = [];
    DocumentForm: FormGroup;
    successMessage = '';
    selectedFile: ImageSnippet;
    selectedFileOriginalName: string;
    uploadingDocumentPercents = 0;
    selectedDocumentFile: ImageSnippet;
    selectedDocumentFilePath: string = null;
    selectedFilePath: string;
    documentValidation: boolean;
    documentEditIndex: number = -1;
    document_id: number;
    areaId: number;
    routes: string;
    errorMessage = '';
    documentErrorMessage = '';
    documentValidationMessage: string = '';
    documentSizeLimit = localStorage.getItem('docsizelimit');
    documentFileSizeLimit = this.formatBytes(this.documentSizeLimit);
    //documentTitle: string;
    uploadingFile = false;
    Utility = Utility;
    filetypeValidation = '';
    uploadingFilePercents = 0;
    companyShortName = Utility.getCompanyShortName();
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();


    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    uploadMultiple(event) {
        if (event.target.files.length > 0) {
            let re = /(?:\.([^.]+))?$/;
            let x = re.exec(event.target.files[0].name);
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.filetypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.filetypeValidation = 'Selected file type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.filetypeValidation = '';
        }
    }

    public uploadDocument(document: any) {
        const formData = new FormData();
        formData.append('file', document);
        formData.append('filename', document.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/documents');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingFilePercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });

       /* this.http.post(Utility.serviceStorageURL() + '/uploadfile', formData).subscribe((res: Response) => {
          this.selectedFilePath = res.json();
        });*/
    }


    /*uploadDocument(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.filetypeValidation = '';
                if (event.target.files[0].size <= this.documentSizeLimit) {
                    this.documentValidationMessage = '';
                    if (event.target.files) {
                        for (let i = 0; i < event.target.files.length; i++) {
                            let reader = new FileReader();
                            reader.readAsDataURL(event.target.files[i]);
                            reader.onload = (events: any) => {
                                this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                                this.uploadImage(this.selectedFile.file);
                            };
                        }
                    }
                } else {
                    this.selectedFilePath = null;
                    this.documentValidation = true;
                    this.filetypeValidation = '';
                    this.documentValidationMessage = 'Please upload document as per given size';
                    this.alertService.validationAlert();
                }
            } else {
                this.filetypeValidation = 'Selected file type must be .pdf';
                this.alertService.validationAlert();
            }
        }
    }

    public uploadImage(document: any) {
        const formData = new FormData();
        formData.append('file', document);
        formData.append('filename', document.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/quotationDocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingDocumentPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }*/


    /*insertDocument(document) {
        if (!Utility.isNull(this.selectedFilePath)) {
            if (this.DocumentForm.controls.title.valid && this.documentValidationMessage.length <= 0 && this.filetypeValidation == '') {
                this.documentValidation = false;
                this.insertDocuments();
            }
        } else {
            if (this.DocumentForm.controls.title.valid && this.DocumentForm.controls.document.valid && this.documentValidationMessage.length <= 0 && this.filetypeValidation == '') {
                this.documentValidation = false;
                this.insertDocuments();
            } else {
                this.documentValidation = true;
                this.alertService.validationAlert();
            }
        }
    }

    insertDocuments() {
        if (this.documentEditIndex !== undefined && this.documentEditIndex >= 0) {
            this.Documents[this.documentEditIndex].title = $('#title').val();
            this.Documents[this.documentEditIndex].documentpath = this.selectedFilePath;
        } else {
            let customObj = new Document();
            customObj.title = $('#title').val();
            customObj.documentpath = this.selectedFilePath ? this.selectedFilePath : null;
            customObj.entitystate = 'Added';
            this.Documents.push(customObj);
        }
        this.clearDocumentControls();
    }
    editDocument(i, editdata) {
        $('#document').val('');
        $('#title').val(editdata.title);
        this.documentTitle = editdata.title;
        this.selectedFilePath = editdata.documentpath;
        this.documentEditIndex = i;
        if (!Utility.isNull(this.selectedFilePath)) {
            $('.showDocPreview').show();
            this.documentValidation = false;
        } else {
            $('.showDocPreview').hide();
        }
    }

    deleteDocument(id) {
        if (confirm('Are you sure you want to delete this record?')) {
            this.Documents.splice(id, 1);
        }
    }

    clearDocumentControls() {
        $('#title').val('');
        $('#document').val('');
        this.selectedFilePath = null;
        this.documentEditIndex = -1;
        this.documentTitle = null;
        $('.showDocPreview').hide();
        $('#frmDocumentUpload').get(0).reset();
    }
*/
    saveDocument(document) {
        if (this.DocumentForm.valid && this.filetypeValidation == '') {
            document.document = this.selectedFilePath ? this.selectedFilePath : null;
            document.created_by = Utility.getLoggedUserId();
            document.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/insertdocument', document).subscribe((res: Response) => {
                    this.router.navigate(['/documents']);
            });
        } else {
            this.alertService.validationAlert();
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menutools').addClass('active');


        this.title.setTitle('Octanics - Document');

        this.route.params.subscribe((res) => {
            this.routes = Common.getRoute(this.router.url);
        });

        this.DocumentForm = new FormGroup({
            title: new FormControl('', Validators.required),
            document: new FormControl('', Validators.required)
        });

        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
    }
}
