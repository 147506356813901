import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {Sorting} from "@app/common/sorting";

declare var $: any;

@Component({
  selector: 'app-documentlist',
  templateUrl: './documentlist.component.html',
  styleUrls: ['./documentlist.component.scss']
})
export class DocumentlistComponent implements OnInit {

    constructor(private http: Http, private title: Title, private datePipe: DatePipe) {
    }

    searchText;
    document: Document;
    Common = Common;
    documents = [];
    p: Number = 1;
    count: Number = 20;
    junctionbox = Utility.junctionboxPath();


    getList = function () {
        this.http.get(Utility.serviceCoreURL + "/documents").subscribe(data => {
            this.documents = data.json();
        });
    };


    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menutools').addClass('active');
        this.getList();
        this.title.setTitle('Octanics - Documents');
    }

}
