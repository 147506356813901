import { Injectable } from '@angular/core';
import {Utility} from './utility';
import {Router} from '@angular/router';
//import {Authorisation} from './authorisation';
import {Localstorage} from './localstorage';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class TokenService {
  private data: any;
  constructor(private http: HttpClient, private router: Router, private Localsecurestorage: Localstorage){

    // this._messageService.currentDetail .subscribe(detail=>{
    //   console.log(detail['token']);  //detail is data you send in shared service
    // });
  }
  logout() {
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token).subscribe((res) => {
      res = res;
      if (res['success'] == 'true') {
          this.http.post(Utility.serviceAuthorisationURL + '/updatetoken', {id: this.Localsecurestorage.getStorage('userid'), token: null}).subscribe((data) => {});
        }
      this.Localsecurestorage.clearStorage();
        localStorage.clear();
        this.router.navigate(['/login']);
    });
  }
  checkTokenAtLogin(){
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token).subscribe((res) => {
      if (res !== '' && res !== null && res !== undefined) {
        res = res;
        if (res['success'] == 'true') {
          this.checkTokenAtAuthenticate();
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }
  checkTokenAtAuthGuard() {
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Utility.serviceAuthorisationURL + '/authenticatetoken?token=' + localStorage.getItem('token')+'&id='+this.Localsecurestorage.getStorage('userid')).subscribe((data) => {
      this.data = data;
    });
    this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token).subscribe(res => {
      if (res !== '' && res !== null && res!== undefined) {
        res = res;
        if (res['success'] == 'true') {
          this.checkTokenAtAuthenticate();
        } else if (this.data['success'] == 'true') {
          this.checkTokenAtAuthenticate();
        } else {
          alert('You have been signed out because you are currently signed in another device');
          this.router.navigate(['/login']);
        }
      }
    });
  }

  checkTokenAtAuthenticate(){
    this.http.get(Utility.serviceAuthorisationURL + '/authenticatetoken?token=' + localStorage.getItem('token') +'&id='+this.Localsecurestorage.getStorage('userid')).subscribe((data) => {
      data = data;
      if(data!==null && data!='') {
        this.http.post(Utility.serviceAuthorisationURL + '/updatetoken', {id: this.Localsecurestorage.getStorage('userid'), token: data['token']}).subscribe((res) => {
            localStorage.setItem('token', data['token']);
        });
      }

    });
  }

}
