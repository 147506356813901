import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from '../common/utility';
import {FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import * as bcrypt from 'bcryptjs';

import {Localstorage} from '../common/localstorage';
import {TokenService} from '../common/token.service';
import {HttpClient} from '@angular/common/http';
import {log} from "util";
import {Lookup} from '../common/lookup';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private lookUp: Lookup, private title: Title,
              private route: ActivatedRoute, private Localsecurestorage: Localstorage, private Token: TokenService) { }

  id: string;
  userDetail: object = {};
  errorMessage = '';
  LoginForm: FormGroup;
  status: boolean;
  pageRow;
  systemapi :any;
  employerDetails = [];
  userid: string;
  addresstypes = [];
  registeredId: any;
  business_id;

  getSystemApi(){
    this.http.get(Utility.serviceCoreURL + '/getsystemapis').subscribe(systemapidata => {
      this.systemapi = systemapidata;

      let core = this.systemapi.find(x => x.name == 'core');
      if (!Utility.isUndefined(core))
        localStorage.setItem('serviceCoreURL', core['url']);

      let storage = this.systemapi.find(x => x.name == 'storage');
      if (!Utility.isUndefined(storage))
        localStorage.setItem('serviceStorageURL', storage['url']);

      let analytics = this.systemapi.find(x => x.name == 'analytics');
      if (!Utility.isUndefined(analytics))
        localStorage.setItem('serviceAnalyticsURL', analytics['url']);

      let integration = this.systemapi.find(x => x.name == 'integration');
      if (!Utility.isUndefined(integration))
        localStorage.setItem('serviceIntegrationURL', integration['url']);

      let logging = this.systemapi.find(x => x.name == 'logging');
      if (!Utility.isUndefined(logging))
        localStorage.setItem('serviceLoggingURL', logging['url']);

      let junctionboxpath = this.systemapi.find(x => x.name == 'junctionboxpath');
      if (!Utility.isUndefined(junctionboxpath))
        localStorage.setItem('junctionBoxPathUrl', junctionboxpath['url']);

      let junctionboxsubdomain = this.systemapi.find(x => x.name == 'junctionboxsubdomain');
      if (!Utility.isUndefined(junctionboxsubdomain))
        localStorage.setItem('junctionBoxSubDomain', junctionboxsubdomain['url']);

      let froalaeditorkey = this.systemapi.find(x => x.name == 'froalaeditorkey');
      if (!Utility.isUndefined(froalaeditorkey))
        localStorage.setItem('froalaEditorKey', froalaeditorkey['url']);

      let databoxpath = this.systemapi.find(x => x.name == 'databoxpath');
      if (!Utility.isUndefined(databoxpath))
        localStorage.setItem('dataBoxPathUrl', databoxpath['url']);
    });
  }
  validateLogin = function (login) {
    console.log('run');
    if (!this.LoginForm.valid) {
    } else {
      console.log('run');
      this.http.get(Utility.serviceAuthorisationURL + '/getuser?email=' + login.username).subscribe(data => {
        if (data === '' || data ===null) {
          this.errorMessage = 'Invalid Username and Password !';
        } else {
          console.log('run');
          this.userDetail = data;
            this.status = bcrypt.compareSync(login.password, this.userDetail['password']);
            if (this.status === true) {
              // this.http.get(Utility.serviceCoreURL + '/getuser?email=' + login.username).subscribe(data2 => {
              this.http.post(Utility.serviceAuthorisationURL + '/login', {email: login.username}).subscribe(res => {
                /*if (data2['_body'] === '') { // if Json returns null

                    this.errorMessage = 'Invalid Username and Password !';
                } else {
                    this.userDetail = data2.json();
                    localStorage.setItem('userid', this.userDetail['id']);
                    localStorage.setItem('email', this.userDetail['email']);
                    localStorage.setItem('username', this.userDetail['username']);
                    localStorage.setItem('rolename', this.userDetail['rolename']);
                    if (this.userDetail) {
                        this.router.navigate(['/dashboard']);
                    }
                }*/
                let token = res;
                // this._messageService.setUser(token);
                localStorage.setItem('userid', this.userDetail['id']);
                  // this.Token.setValueinLocalstorage(token);
                localStorage.setItem('token', token['token']);
                this.http.post(Utility.serviceAuthorisationURL + '/updatetoken', {id: localStorage.getItem('userid'),token:token['token']}).subscribe((data) => {
                  this.Localsecurestorage.setStorage('userid',this.userDetail['id']);
                  this.Localsecurestorage.setStorage('useroleid',this.userDetail['roleid']);
                  this.Localsecurestorage.setStorage('useemail', this.userDetail['email']);
                  this.Localsecurestorage.setStorage('username', this.userDetail['username']);
                  // this.Localsecurestorage.getStorage('useemail');
                  localStorage.setItem('email', this.userDetail['email']);

                  localStorage.setItem('roleid', this.userDetail['roleid']);
                  localStorage.setItem('username', this.userDetail['username']);
                  // localStorage.setItem('rolename', this.userDetail['rolename']);
                  localStorage.setItem('profilephoto', this.userDetail['profilephoto']);
                  //localStorage.setItem('aliasrolename', this.userDetail['aliasrolename']);
                  localStorage.setItem('password', login.password);
                  if (this.userDetail) {
                    this.router.navigate(['/dashboard']);
                  }
                });
              });
                this.userid = this.userDetail['id'];
                this.http.get(Utility.serviceCoreURL + '/getbusinessid?user_id=' + this.userid).subscribe(businessid => {
                    if (!Utility.isNull(businessid)) {
                        localStorage.setItem('businessid', businessid);
                        this.business_id = businessid;
                        this.http.get(Utility.serviceCoreURL + '/getemployers?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(empdata => {
                            this.employerDetails = empdata;
                            localStorage.setItem('companyname', this.employerDetails['employerdetail']['nameoforganization']);
                            localStorage.setItem('companymobileno', this.employerDetails['employerdetail']['mobilenoofowner']);
                            localStorage.setItem('companyshortname', this.employerDetails['employerdetail']['businessshortname']);
                        });
                    }
                });
                this.http.get(Utility.serviceCoreURL + '/getdocumentsizelimit').subscribe(docsizelimit => {
                    if (!Utility.isNull(docsizelimit)) {
                        localStorage.setItem('docsizelimit', docsizelimit);
                    }
                });
            } else {
              this.errorMessage = 'Invalid Username and Password !';
            }
        }
      });
     }
    };
  getPageRow(){
    // this.http.get(Utility.serviceCoreURL + '/getdefaultrowvalue').subscribe(data => {
    //     if (data['_body'] !== '' && data['_body'] !== null && data['_body'] !== undefined) {
    //         this.pageRow = data.json();
    //     }else{
    //         this.pageRow = 20;
    //     }
    this.pageRow = 20;
    localStorage.setItem('pageSize', this.pageRow);
    // });
  }

  ngOnInit() {
    this.id = localStorage.getItem('userid');
    this.title.setTitle('Octanics Autobots - Login');
    //this.Token.checkTokenAtLogin();
    this.getPageRow();
    this.getSystemApi();
    this.LoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
    this.lookUp.getLookUp('addresstypes').subscribe((data) => {
        if (data) {
            this.addresstypes = data;
            let type = this.addresstypes.find(x => x.code == 'REGISTERED');
            this.registeredId = type['id'];
        }
    });
  }


}
